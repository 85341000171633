import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
const firebaseConfig = {
    apiKey: "AIzaSyCkjsxsjhBNOUz6LwtUX1xxn9oMJ77UVEE",
    authDomain: "thedevpiyush.firebaseapp.com",
    databaseURL: "https://thedevpiyush-default-rtdb.firebaseio.com",
    projectId: "thedevpiyush",
    storageBucket: "thedevpiyush.appspot.com",
    messagingSenderId: "62463147843",
    appId: "1:62463147843:web:44591913bf9d0a00c3f9f6",
    measurementId: "G-KMWBD98W28"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore();